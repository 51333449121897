<template>
  <div class="page-wrapper">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="Email" prop="email">
        <el-input
          v-model="ruleForm.email"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
        <el-button @click="resetForm('ruleForm')">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import { login } from "@/services"
export default {
  name: "Login",
  data() {
    return {
      ruleForm: {
        email: '',
        password: '',
      },
      rules: {
        pass: [{ validator: this.validatePass, trigger: 'blur' }],
        // checkPass: [{ validator: this.validatePass2, trigger: 'blur' }],
        email: [{ validator: this.checkEmail, trigger: 'blur' }],
      },
    }
  },
  methods: {
    checkEmail(rule, value, callback) {
      if (!value) {
        return callback(new Error('Please input the email'))
      }
      setTimeout(() => {
        // if (!Number.isInteger(value)) {
        //   callback(new Error('Please input digits'))
        // } else {
        //   if (value < 18) {
        //     callback(new Error('Age must be greater than 18'))
        //   } else {
        //     callback()
        //   }
        // }
        callback()
      }, 100)
    },
    validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    },
    // validatePass2(rule, value, callback) {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else if (value !== this.ruleForm.pass) {
    //     callback(new Error("Two inputs don't match!"))
    //   } else {
    //     callback()
    //   }
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.login()
          this.$store.dispatch('loginUser', this.ruleForm).then(response => {
            if (response.status === 'success') {
              this.$router.push("/")
            }
          })
        } else {
          console.log('error')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style scoped>
.page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>